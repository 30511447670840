import Vue from "vue";
import Vuex from "vuex";
import admin from "./modules/admin/admin";
import components from "./modules/components/components";
import saleProcess from "./modules/saleProcess/saleProcess";
import user from "./modules/user/user";
import storage from "./modules/storage/storage";
import shoppingCart from "./modules/shoppingCart/shoppingCart";
import exception from "./modules/exception/exception";
import productModificationProcess from "./modules/productModificationProcess/productModificationProcess";
import createPersistedState from "vuex-persistedstate";
import approval from "./modules/approval/approval";
import sale from "./modules/sale/sale";
import customer from "./modules/customer/customer";
import productTransitionProcess from "./modules/productTransitionProcess/productTransitionProcess";
import productMultipleTransitionProcess from "./modules/productMultipleTransitionProcess/productMultipleTransitionProcess";
import apartment from "./modules/apartment/apartment";
import newExtrasProcess from "./modules/newExtrasProcess/newExtrasProcess";

Vue.use(Vuex);

const debug = process.env.NODE_ENV !== "production";

export default new Vuex.Store({
	modules: {
		admin,
		approval,
		components,
		saleProcess,
		shoppingCart,
		user,
		storage,
		exception,
		productModificationProcess,
		sale,
		customer,
		productTransitionProcess,
		productMultipleTransitionProcess,
		apartment,
		newExtrasProcess
	},
	strict: debug,
	plugins: [
		createPersistedState({
			storage: window.sessionStorage
		})
	]
});
