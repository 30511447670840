import httpClient from "@/api/config/httpClient";
import { TrackingFilter } from "@/api/models/tracking/trackingFilter.model";

export abstract class SaleService {
	private static readonly SALE_URL = "/sys03/sale";
	private static readonly DASHBOARD_URL = SaleService.SALE_URL.concat("/dashboard");
	private static readonly DASHBOARD_CSV_URL = SaleService.DASHBOARD_URL.concat("/csv");

	private static getDashboardParams({ filters }: { filters: TrackingFilter }): Record<string, string | number | boolean> {
		const { limit, page, id, phone, smsPhone, dateFrom, dateTo, user, status, lastChannel, customerType, customerId } = filters;
		const query: Record<string, string | number | boolean> = {
			limit,
			page,
			id,
			phone,
			smsPhone,
			dateFrom,
			dateTo,
			lastChangeMadeBy: user,
			status,
			lastChannel,
			customerType,
			customerId
		};
		const queryFilterd = Object.entries(query).filter(([, value]) => {
			if (typeof value === "string") return value.length > 0;
			return true;
		});

		return Object.fromEntries(queryFilterd);
	}

	public static getDashboardInformation(filters: TrackingFilter) {
		const url = `${SaleService.DASHBOARD_URL}`;
		const params = SaleService.getDashboardParams({ filters });

		return httpClient.get(url, { params });
	}

	public static getDashboardCsv(filters: TrackingFilter) {
		const url = `${SaleService.DASHBOARD_CSV_URL}`;
		const params = SaleService.getDashboardParams({ filters });

		return httpClient.get(url, { params });
	}
}
