import Vue from "vue";
import App from "./App.vue";
import { router } from "./router";
import store from "./store";
import vuetify from "./plugins/vuetify";
import "./assets/css/styles.scss";
import { i18n } from "./i18n";
import * as VueGoogleMaps from "vue2-google-maps";
import dayjs from "dayjs";
import VueMask from "v-mask";
import { ApmVuePlugin } from "@elastic/apm-rum-vue";
import "./directives";

declare module "vue/types/vue" {
	interface Vue {
		$formatDate: (value: string | undefined, format: string) => string;
	}
}

const isDevEnv = process.env.NODE_ENV === "development" || process.env.VUE_APP_NODE_ENV_LOCAL === "true";
const apmActive = process.env.VUE_APP_APM_ACTIVE === "true";

Vue.config.productionTip = false;

Vue.use(VueGoogleMaps, {
	load: {
		key: process.env.VUE_APP_GOOGLE_MAPS_API_KEY,
		libraries: "places"
	}
});

Vue.use(VueMask);

Vue.directive("uppercase", {
	update(el: any) {
		const input = el.querySelector("input");

		if (input) {
			const sourceValue = input.value;
			const newValue = sourceValue.toUpperCase();

			if (sourceValue !== newValue) {
				input.value = newValue;
				input.dispatchEvent(new Event("input", { bubbles: true }));
			}
		}
	}
});

Vue.mixin({
	methods: {
		$formatDate: (value: string, format: string): string => {
			if (!value) return "";

			return dayjs(value).format(format);
		}
	}
});

const $store = store as any;
$store.commit("checkAuth");

if ($store.state?.user?.logged) {
	$store.dispatch("loadPermissions").then((success: boolean) => {
		if (!success) {
			$store.dispatch("logout").then(() => initApp());
		} else {
			initApp();
		}
	});
} else {
	initApp();
}

function initApp() {
	if (process.env.VUE_APP_APM_SERVER_URL !== "") {
		Vue.use(ApmVuePlugin, {
			router,
			config: {
				serviceName: process.env.VUE_APP_APM_SERVICE_NAME,
				environment: process.env.VUE_APP_APM_ENV,
				serverUrl: process.env.VUE_APP_APM_SERVER_URL,
				active: !isDevEnv && apmActive
			}
		});
	}

	new Vue({
		router,
		store,
		vuetify,
		i18n,
		render: (h) => h(App),
		provide() {
			return {
				$dayjs: dayjs
			};
		}
	}).$mount("#app");
}
