import httpClient from "@/api/config/httpClient";
import { ApprovalDto } from "@/models/database.model";
import { ApprovalFilter } from "@/api/models/approval/approval.model";

export abstract class ApprovalService {
	private static readonly APPROVAL_URL = "/sys03/approval";
	private static readonly APPROVAL_DATA_URL = ApprovalService.APPROVAL_URL.concat("/data");
	private static readonly DASHBOARD_URL = ApprovalService.APPROVAL_URL.concat("/dashboard");
	private static readonly DASHBOARD_CSV_URL = ApprovalService.DASHBOARD_URL.concat("/csv");
	private static readonly DASHBOARD_DATA_CSV_URL = ApprovalService.DASHBOARD_URL.concat("/data/csv");

	private static getDashboardParams({ filters }: { filters: ApprovalFilter }): Record<string, string | number | boolean> {
		const { limit, page, id, leadId, phone, shoppingCartPhone, owner, channel, customerType, approvedBy, approved, validation, dateFrom, dateTo } =
			filters;
		const query: Record<string, string | number | boolean> = {
			limit,
			page,
			id,
			leadId,
			phone,
			shoppingCartPhone,
			owner,
			channel,
			customerType,
			approvedBy,
			approved,
			validation,
			dateFrom,
			dateTo
		};
		const queryFilterd = Object.entries(query).filter(([, value]) => {
			if (typeof value === "string") return value.length > 0;
			return true;
		});

		return Object.fromEntries(queryFilterd);
	}

	public static getApprovalById(id: string) {
		const url = `${ApprovalService.APPROVAL_URL}/${id}`;
		return httpClient.get(url);
	}

	public static patchApprovalData(approvalDataId: string, data: any) {
		const url = `${ApprovalService.APPROVAL_DATA_URL}/${approvalDataId}`;
		return httpClient.patch(url, data);
	}

	public static checkApprovalById(approvalDataId: string): Promise<Partial<ApprovalDto>> {
		const url = `${ApprovalService.APPROVAL_URL}/${approvalDataId}/check`;
		return httpClient.put(url).then((response) => response.data);
	}

	public static getDashboardInformation(filters: ApprovalFilter) {
		const url = `${ApprovalService.DASHBOARD_URL}`;
		const params = ApprovalService.getDashboardParams({ filters });

		return httpClient.get(url, { params });
	}

	public static getDashboardCsv(filters: ApprovalFilter) {
		const url = `${ApprovalService.DASHBOARD_CSV_URL}`;
		const params = ApprovalService.getDashboardParams({ filters });

		return httpClient.get(url, { params });
	}

	public static getDashboardDataCsv(filters: ApprovalFilter) {
		const url = `${ApprovalService.DASHBOARD_DATA_CSV_URL}`;
		const params = ApprovalService.getDashboardParams({ filters });

		return httpClient.get(url, { params });
	}

	public static getDataCsvById(id: any) {
		const url = `${ApprovalService.APPROVAL_URL}/${id}/data/csv`;
		return httpClient.get(url);
	}
}
