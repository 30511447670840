const mapMarkers = (state) => {
	const addresses = state.addresses.filter((address, index, self) => {
		return index === self.findIndex((selfAddress) => selfAddress.id === address.id);
	});
	const markers = addresses.map((address) => {
		return {
			id: address.id,
			position: {
				lat: Number(address.lat),
				lng: Number(address.lng)
			}
		};
	});
	return markers ? markers : [];
};

const areAddressesResults = (state) => {
	return state.addresses.length > 0 || state.apartments.length > 0;
};

export default {
	mapMarkers,
	areAddressesResults
};
