import { RouteInfo } from "./types";
import { authGuard, permissionGuard } from "./guards/index";
import { UIPAGE } from "@/constants/ui-page.constants";

const ViewCustomers = () => import(/* webpackChunkName: "chunk-customers" */ "@/views/customer/Customers.vue");

export const CustomersRoute: RouteInfo = {
	id: UIPAGE.CUSTOMERS,
	path: "/customers",
	component: ViewCustomers,
	guards: [authGuard, permissionGuard],
	title: "customers.display",
	icon: "mdi-account-group-outline"
};
