import Vue from "vue";
import Vuetify from "vuetify/lib/framework";

Vue.use(Vuetify);

import SmallMapMarker from "@/components/icons/SmallMapMarker.vue";
import OutlinedMapMarker from "@/components/icons/OutlinedMapMarker.vue";
import BoxSelect from "@/components/icons/BoxSelect.vue";
import Mobile from "@/components/icons/Mobile.vue";
import Telephone from "@/components/icons/Telephone.vue";
import Router from "@/components/icons/Router.vue";
import TV from "@/components/icons/TV.vue";
import VoipCloud from "@/components/icons/VoipCloud.vue";
import DigitalService from "@/components/icons/DigitalService.vue";
import "@mdi/font/css/materialdesignicons.css";

export default new Vuetify({
	theme: {
		themes: {
			light: {
				primary: "#000000",
				secondary: "#f9f9f9",
				accent: "#b14f83",
				error: "#f64c74",
				success: "#5eb780",
				statusOrgangeLight: "#f89515",
				statusOrgangeLightBackground: "#fddaad"
			}
		}
	},
	icons: {
		iconfont: "mdi", // default - only for display purposes
		values: {
			smallMapMarker: {
				component: SmallMapMarker
			},
			outlinedMapMarker: {
				component: OutlinedMapMarker
			},
			boxSelect: {
				component: BoxSelect
			},
			telephone: {
				component: Telephone
			},
			mobile: {
				component: Mobile
			},
			router: {
				component: Router
			},
			tv: {
				component: TV
			},
			voipCloud: {
				component: VoipCloud
			},
			digitalService: {
				component: DigitalService
			}
		}
	}
});
