import { SaleService } from "@/api/services/sale.service";
import { TrackingFilter } from "@/api/models/tracking/trackingFilter.model";
import { downloadFile } from "@/utils/file.util";

const getSalesDashboardInfo = ({ commit }: any, filters: TrackingFilter) => {
	return new Promise((resolve, reject) => {
		SaleService.getDashboardInformation(filters)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getSalesDashboardCsv = ({ commit }: any, filters: any) => {
	return new Promise((resolve, reject) => {
		SaleService.getDashboardCsv(filters)
			.then((response) => {
				downloadFile(response.data, "dashboard-sales.csv", "text/csv");
				resolve(response.data);
			})
			.catch(reject);
	});
};

export default {
	getSalesDashboardInfo,
	getSalesDashboardCsv
};
