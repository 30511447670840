import { AddressService } from "@/api/services/address.service";
import { SaleProcessStatus, SearchType, GooglePlaceDataType } from "@/api/constants/enums";
import { router } from "@/router/index";
import store from "@/store/index";
import { GeneralDialogMode } from "@/api/constants/enums";

const loadAddressesByPostalCode = ({ commit }: any, { zip, allStatuses }: { zip: string; allStatuses: boolean }) => {
	commit("activateOverlay", "sale.steps.step2.searching_addresses");
	return new Promise((resolve, reject) => {
		return AddressService.getAddressesByPostalCode({ zip, allStatuses })
			.then((response) => {
				if (response.status === 204) {
					resolve(null);
				} else {
					commit("saveAddresses", response.data);
					resolve(response.data);
				}
			})
			.catch(reject)
			.finally(() => commit("desactivateOverlay"));
	});
};

const searchAddressesByPostalCode = ({ commit, dispatch }: any, { zip, allStatuses }: { zip: string; allStatuses: boolean }) => {
	commit("activateOverlay", "sale.steps.step2.searching_addresses");
	commit("updateAddress", undefined);
	commit("updateApartment", undefined);
	commit("resetAddressesAndApartments");
	return new Promise((resolve, reject) => {
		return dispatch("loadAddressesByPostalCode", { zip, allStatuses })
			.then((response) => {
				const saleProcessId = (store["state"] as any).saleProcess.id;
				if (response && saleProcessId !== "") {
					const saleProcess = {
						status: SaleProcessStatus.ADDRESS_SEARCH,
						addressSearch: {
							searchType: SearchType.ZIP,
							search: zip
						}
					};
					dispatch("updateSaleProcess", saleProcess).then(() => {
						resolve(true);
					});
				} else {
					resolve(null);
				}
			})
			.catch((error) => {
				commit("updateAddress", undefined);
				reject(error);
			})
			.finally(() => commit("desactivateOverlay"));
	});
};

const loadAddressesByGooglePlace = ({ commit }: any, { googlePlace, allStatuses }: { googlePlace: any; allStatuses: boolean }) => {
	commit("activateOverlay", "sale.steps.step2.searching_addresses");
	return new Promise((resolve, reject) => {
		const coordinates = googlePlace?.geometry.location.toJSON();
		return AddressService.getAddressesByCoordinates({ lng: coordinates?.lng, lat: coordinates?.lat, allStatuses })
			.then((response) => {
				if (response.status === 200) {
					commit("saveAddresses", response.data);
					resolve(response.data);
				} else if (response.status === 204) {
					resolve(null);
				}
			})
			.catch(reject)
			.finally(() => commit("desactivateOverlay"));
	});
};
const loadAddressesByCoordinates = ({ commit }: any, { lng, lat, allStatuses }: { lng: string; lat: string; allStatuses: boolean }) => {
	commit("activateOverlay", "sale.steps.step2.searching_addresses");
	return new Promise((resolve, reject) => {
		return AddressService.getAddressesByCoordinates({ lng, lat, allStatuses })
			.then((response) => {
				commit("setAddressSearchedCoords", {
					lat: Number(lat),
					lng: Number(lng)
				});
				if (response.status === 200) {
					commit("saveAddresses", response.data);
					resolve(response.data);
				} else if (response.status === 204) {
					resolve(null);
				}
			})
			.catch(reject)
			.finally(() => commit("desactivateOverlay"));
	});
};

const searchAddressesByCoordinates = ({ commit, dispatch }: any, { lng, lat, allStatuses }: { lng: string; lat: string; allStatuses: boolean }) => {
	commit("activateOverlay", "sale.steps.step2.searching_addresses");
	commit("updateAddress", undefined);
	commit("updateApartment", undefined);
	commit("resetAddressesAndApartments");
	return new Promise((resolve, reject) => {
		return AddressService.getAddressesByCoordinates({ lng, lat, allStatuses })
			.then((response) => {
				const saleProcessId = (store["state"] as any).saleProcess.id;
				if (response && saleProcessId !== "") {
					const saleProcess = {
						status: SaleProcessStatus.ADDRESS_SEARCH,
						addressSearch: {
							searchType: SearchType.COORDS,
							googleLatitude: String(lat),
							googleLongitude: String(lng)
						}
					};
					dispatch("updateSaleProcess", saleProcess).then(() => {
						resolve(true);
					});
				}

				commit("setAddressSearchedCoords", {
					lat: Number(lat),
					lng: Number(lng)
				});
				if (response.status === 200) {
					commit("saveAddresses", response.data);
					resolve(response.data);
				} else if (response.status === 204) {
					resolve(null);
				}
			})
			.catch(reject)
			.finally(() => commit("desactivateOverlay"));
	});
};

const searchAddressesByGooglePlace = ({ commit, dispatch }: any, { googlePlace, allStatuses }: { googlePlace: any; allStatuses: boolean }) => {
	commit("activateOverlay", "sale.steps.step2.searching_addresses");
	commit("updateAddress", undefined);
	commit("updateApartment", undefined);
	commit("resetAddressesAndApartments");
	return new Promise((resolve, reject) => {
		return dispatch("loadAddressesByGooglePlace", { googlePlace, allStatuses })
			.then((response) => {
				const formattedAddress = formatAddressSearchByGoogle(googlePlace);
				commit("setAddressSearchedCoords", {
					lat: Number(formattedAddress.googleLatitude),
					lng: Number(formattedAddress.googleLongitude)
				});
				const saleProcessId = (store["state"] as any).saleProcess.id;
				if (response && saleProcessId !== "") {
					const saleProcess = {
						status: SaleProcessStatus.ADDRESS_SEARCH,
						addressSearch: formatAddressSearchByGoogle(googlePlace)
					};
					if (saleProcess.addressSearch.googleZip === "") {
						saleProcess.addressSearch.googleZip = undefined;
					}

					dispatch("updateSaleProcess", saleProcess).then(() => {
						resolve(true);
					});
				} else {
					resolve(null);
				}
			})
			.catch((error) => {
				commit("updateAddress", undefined);
				reject(error);
			})
			.finally(() => commit("desactivateOverlay"));
	});
};

const loadApartmentsByAddressId = ({ commit, dispatch }: any, { id, allStatuses }: { id: string; allStatuses: boolean }) => {
	commit("activateOverlay", "sale.steps.step2.searching_apartments");
	return new Promise((resolve, reject) => {
		return AddressService.getAddressById({ id, allStatuses })
			.then((response) => {
				const saleProcessId = (store["state"] as any).saleProcess.id;
				commit("saveApartments", response.data.apartments);
				if (saleProcessId !== "") {
					const saleProcess = {
						status: SaleProcessStatus.ADDRESS_SELECTED,
						addressSearch: {
							coberturaId: id.toString()
						}
					};
					dispatch("updateSaleProcess", saleProcess).then(() => {
						resolve(response.data);
					});
				} else {
					resolve(null);
				}
			})
			.catch(reject)
			.finally(() => commit("desactivateOverlay"));
	});
};

const getAddressById = ({ commit }: any, { id, allStatuses }: { id: string; allStatuses: boolean }) => {
	return new Promise((resolve, reject) => {
		return AddressService.getAddressById({ id, allStatuses })
			.then((response) => {
				if (typeof response.data === "object") {
					commit("updateAddress", response.data);
					commit("saveApartments", response.data.apartments);
					commit("saveAddresses", [response.data]);
					resolve(response.data);
				} else {
					commit("updateAddress", undefined);
					resolve(undefined);
				}
			})
			.catch((error) => {
				commit("updateAddress", undefined);
				commit("updateApartment", undefined);
				reject(error);
			});
	});
};

const getApartmentById = ({ commit }: any, apartmentId: string) => {
	return new Promise((resolve, reject) => {
		return AddressService.getApartmentById(apartmentId)
			.then((response) => {
				if (response.status === 204) {
					commit("updateAddress", undefined);
					commit("updateApartment", undefined);
					resolve(null);
				} else {
					commit("updateApartment", response.data);
					//commit("saveApartments", [response.data]);
					if (response.data.streetAddressId !== "0" && Number(response.data.streetAddressId) !== 0) {
						// set address for SignalStep
						commit("updateAddress", { id: response.data.streetAddressId });
						resolve(response.data);
					} else {
						// set address for SignalStep
						commit("updateAddress", { id: "0" });
						commit("saveApartments", [response.data]);
						commit("saveAddresses", [
							{
								id: "0",
								lat: response.data.lat,
								lng: response.data.lng
							}
						]);
						resolve(response.data);
					}
				}
			})
			.catch((error) => {
				commit("updateAddress", undefined);
				commit("updateApartment", undefined);
				reject(error);
			});
	});
};
const searchApartmentById = ({ commit, dispatch }: any, apartmentId: string) => {
	commit("activateOverlay", "sale.steps.step2.searching_apartment");
	return new Promise((resolve, reject) => {
		return dispatch("getApartmentById", apartmentId)
			.then((apartment) => {
				if (!apartment) {
					commit("resetAddressesAndApartments");
					resolve(null);
				}

				commit("activateOverlay", "sale.steps.step2.saving");
				const saleProcess = {
					status: SaleProcessStatus.APARTMENT_SELECTED,
					addressSearch: {
						searchType: SearchType.ID,
						search: apartmentId,
						coberturaId: apartment.streetAddressId.toString(),
						coberturaPisoPortaId: apartmentId.toString()
					},
					customer: {
						serviceAddress: apartmentId.toString()
					}
				};
				resolve(saleProcess);
			})
			.catch(reject)
			.finally(() => {
				commit("desactivateOverlay");
			});
	});
};

const selectApartment = ({ commit, dispatch }: any, apartmentId: string) => {
	commit("activateOverlay", "sale.steps.step2.saving");
	return new Promise((resolve, reject) => {
		return AddressService.getApartmentById(apartmentId)
			.then((response) => {
				if (response.status === 204) {
					commit("updateAddress", undefined);
					commit("updateApartment", undefined);
					commit("desactivateOverlay");
					resolve(null);
				} else {
					commit("updateApartment", response.data);
					commit("saveApartments", [response.data]);

					const saleProcess = {
						status: SaleProcessStatus.APARTMENT_SELECTED,
						addressSearch: {
							coberturaId: response.data.streetAddressId.toString(),
							coberturaPisoPortaId: apartmentId.toString()
						},
						customer: {
							serviceAddress: apartmentId.toString()
						}
					};
					dispatch("updateSaleProcess", saleProcess)
						.then(() => {
							commit("activateOverlay", "sale.steps.step2.saving");
							router.push({ path: "/sale/customer" });
						})
						.finally(() => commit("desactivateOverlay"));
				}
			})
			.catch((error) => {
				commit("updateAddress", undefined);
				commit("updateApartment", undefined);
				reject(error);
			})
			.finally(() => commit("desactivateOverlay"));
	});
};

const isApartmentAvailable = ({ commit }: any, apartmentId: string) => {
	return new Promise((resolve, reject) => {
		return AddressService.getApartmentById(apartmentId)
			.then((response) => {
				if (response.status === 204) {
					commit("activateDialog", {
						mode: GeneralDialogMode.ERROR,
						i18n: "E_APARTMENT_BREACHES_RETAIL_EXCLUSIVITY_RULES"
					});
					return resolve(false);
				}

				resolve(true);
			})
			.catch(reject);
	});
};

function formatAddressSearchByGoogle(googlePlace: any) {
	const coordinates = googlePlace?.geometry.location.toJSON();
	return {
		searchType: SearchType.GOOGLE,
		search: googlePlace.formatted_address,
		googleStreet: googlePlace.formatted_address,
		googleLatitude: "" + coordinates.lat,
		googleLongitude: "" + coordinates.lng,
		googleProvince: getGooglePlaceData(googlePlace, GooglePlaceDataType.PROVINCE),
		googleZip: getGooglePlaceData(googlePlace, GooglePlaceDataType.ZIP),
		googleCity: getGooglePlaceData(googlePlace, GooglePlaceDataType.CITY)
	};
}

function getGooglePlaceData(googlePlace: any, type: GooglePlaceDataType) {
	const addressComponent = googlePlace.address_components.find((component) => component.types.some((componentType) => componentType === type));
	return addressComponent ? addressComponent.long_name : "";
}

export default {
	loadAddressesByPostalCode,
	searchAddressesByPostalCode,
	loadAddressesByGooglePlace,
	searchAddressesByGooglePlace,
	loadAddressesByCoordinates,
	searchAddressesByCoordinates,
	getAddressById,
	getApartmentById,
	searchApartmentById,
	selectApartment,
	loadApartmentsByAddressId,
	isApartmentAvailable
};
