import { router } from "@/router/index";
import store from "@/store/index";
import axios from "axios";

const authToken = () => localStorage.getItem("token");
const access_token_url = `${process.env.VUE_APP_API_URL_ACCESS_TOKEN}/auth/realms/adamo/protocol/openid-connect/token`;

export const authRequestInterceptorDevelopment = (request: any) => {
	request.headers["api-auth"] = `Bearer ${authToken()}`;
	request.headers["api-secret"] = `Secret ${process.env.VUE_APP_API_SECRET}`;
	return request;
};

export const authRequestInterceptor = (request: any) => {
	const params = new URLSearchParams();
	params.append("grant_type", process.env.VUE_APP_GRANT_TYPE);
	params.append("client_id", process.env.VUE_APP_CLIENT_ID);
	params.append("client_secret", process.env.VUE_APP_CLIENT_SECRET);
	const headers = {
		"Content-Type": "application/x-www-form-urlencoded"
	};
	return axios
		.post(access_token_url, params, { headers, withCredentials: true })
		.then((res) => {
			request.headers["api-auth"] = `Bearer ${authToken()}`;
			request.headers["authorization"] = `Bearer ${res.data.access_token}`;
			request.headers["api-secret"] = `Secret ${process.env.VUE_APP_API_SECRET}`;
			return request;
		})
		.catch((error) => {
			return Promise.reject(error);
		});
};

export const authErrorResponseInterceptor = (error: any) => {
	if (error && error.response && error.response.status === 401) {
		store.dispatch("logout");
		router.push("/login").catch(() => {
			return;
		});
	}
	return Promise.reject(error);
};
