import Vue from "vue";
import VueRouter, { Route, RouteConfig } from "vue-router";
import { rootPathGuard, multipleGuards } from "./guards/index";
import { i18n } from "@/i18n";
import { RouteInfo } from "./types";
import { AdministrationRoute } from "./administration.route";
import { LoginRoute } from "./login.route";
import { DashboardRoute } from "./dashboard.route";
import { TrackingLeadsRoute } from "./tracking-leads.route";
import { CoverageSearchRoute } from "./coverage-search.route";
import { SaleRoute } from "./sale.route";
import { TrackingSalesRoute } from "./tracking-sales.route";
import { ApprovalDetailRoute, ApprovalsRoute } from "./approvals.route";
import { CustomersRoute } from "./customers.route";
import { Customer360Route } from "./customer/customer-360.route";
import { CustomerProductTransitionRoute } from "./customer/customer-product-transition.route";
import {
	CustomerProductOnlyMobilesTransitionRoute,
	CustomerProductSharedMobilesTransitionRoute
} from "./customer/customer-product-multiple-transition.route";
import { CustomerProductModificationRoute } from "./customer/customer-product-modification.route";
import { CustomerProductTerminationRoute } from "./customer/customer-product-termination.route";
import { CustomerNewExtrasRoute } from "./customer/customer-new-extras.route";

Vue.use(VueRouter);

const routes: RouteInfo[] = [
	LoginRoute,
	DashboardRoute,
	SaleRoute,
	TrackingLeadsRoute,
	TrackingSalesRoute,
	CoverageSearchRoute,
	ApprovalsRoute,
	ApprovalDetailRoute,
	CustomersRoute,
	Customer360Route,
	CustomerProductTransitionRoute,
	CustomerProductOnlyMobilesTransitionRoute,
	CustomerProductSharedMobilesTransitionRoute,
	CustomerProductModificationRoute,
	CustomerProductTerminationRoute,
	CustomerNewExtrasRoute,
	AdministrationRoute,
	{
		id: "wildcard",
		path: "*",
		guards: [rootPathGuard]
	}
];

function mapRoutes(routes: RouteInfo[]): RouteConfig[] {
	return routes.map((route) => {
		const { id, path, component, title, guards, children, props, redirect } = route;
		const name = children && children.length ? undefined : id;
		const beforeEnter = guards && guards.length ? multipleGuards(guards) : undefined;

		return {
			name,
			path,
			component,
			beforeEnter,
			props,
			meta: {
				id,
				title
			},
			children: children ? mapRoutes(children) : undefined,
			redirect
		};
	});
}

function filterRoutes(...ids: string[]): RouteInfo[] {
	return routes.reduce((acc: RouteInfo[], route: RouteInfo) => {
		const { id, children = [] } = route;
		if (ids.includes(id)) {
			acc.push(route);
			return acc;
		}

		if (children.length) {
			const childRoutes = children.filter((child) => ids.includes(child.id));
			acc.push(...childRoutes);
		}
		return acc;
	}, []);
}

function findRoute(id: string): RouteInfo | undefined {
	const route = routes.find((route) => route.id === id);
	return route;
}

const router = new VueRouter({
	mode: "history",
	routes: mapRoutes(routes)
});

router.beforeEach((to: Route, from: Route, next: () => void) => {
	window.scrollTo(0, 0);
	window.document.title = to.meta && to.meta.title ? `${i18n.t(to.meta.title)} | ARS` : "ARS";
	next();
});

export { router, routes, findRoute, filterRoutes };
