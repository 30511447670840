// Dashboard

import { ApprovalService } from "@/api/services/approval.service";
import { ApprovalFilter } from "@/api/models/approval/approval.model";
import { downloadFile } from "@/utils/file.util";

const getApprovalById = ({ commit }: any, id: string) => {
	return new Promise((resolve, reject) => {
		ApprovalService.getApprovalById(id)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getApprovalsDashboardInfo = ({ commit }: any, filters: ApprovalFilter) => {
	return new Promise((resolve, reject) => {
		ApprovalService.getDashboardInformation(filters)
			.then((response) => {
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getApprovalsDashboardCsv = ({ commit }: any, filters: any) => {
	return new Promise((resolve, reject) => {
		ApprovalService.getDashboardCsv(filters)
			.then((response) => {
				downloadFile(response.data, "dashboard-approvals.csv", "text/csv");
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getApprovalsDashboardDataCsv = ({ commit }: any, filters: any) => {
	return new Promise((resolve, reject) => {
		ApprovalService.getDashboardDataCsv(filters)
			.then((response) => {
				downloadFile(response.data, "dashboard-data-approvals.csv", "text/csv");
				resolve(response.data);
			})
			.catch(reject);
	});
};

const getDataCsvById = ({ commit }: any, id: any) => {
	return new Promise((resolve, reject) => {
		ApprovalService.getDataCsvById(id)
			.then((response) => {
				downloadFile(response.data, `data-${id}.csv`, "text/csv");
				resolve(response.data);
			})
			.catch(reject);
	});
};

export default {
	getApprovalById,
	getApprovalsDashboardInfo,
	getApprovalsDashboardCsv,
	getApprovalsDashboardDataCsv,
	getDataCsvById
};
