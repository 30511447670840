export const approvalInitialState = () => {
	return {
		id: -1,
		leadId: -1,
		validations: [],
		approved: null,
		createdBy: "",
		createdAt: "",
		approvedBy: "",
		approvedAt: "",
		updatedBy: "",
		updatedAt: "",
		owner: "",
		channel: "",
		phones: [],
		apartmentId: "",
		customerType: "",
		identificationType: "",
		identificationNumber: "",
		identificationCountry: "",
		cifType: "",
		cifNumber: "",
		cifCountry: "",
		lastName: "",
		billingLastName: "",
		email: "",
		iban: "",
		shoppingCartId: "",
		shoppingCartPhones: [],
		groups: []
	};
};

const state: Approval = approvalInitialState();
import actions from "./actions";
import { Approval } from "@/api/models/approval/approval.model";

export default {
	state,
	actions
};
